// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-c-js": () => import("./../../src/pages/C.js" /* webpackChunkName: "component---src-pages-c-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-js": () => import("./../../src/pages/Data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-elements-js": () => import("./../../src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../../src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-management-js": () => import("./../../src/pages/Management.js" /* webpackChunkName: "component---src-pages-management-js" */),
  "component---src-pages-service-js": () => import("./../../src/pages/Service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-sql-js": () => import("./../../src/pages/SQL.js" /* webpackChunkName: "component---src-pages-sql-js" */)
}

